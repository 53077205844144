import styled from 'styled-components';

export default styled.h1`
  margin: 2em 0 1.2em;
  line-height: 1.2;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  ${p => p.theme.ml} {
    font-size: 36px;
  }
`;
