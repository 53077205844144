import styled from 'styled-components';

export default styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  ${p => p.theme.ml} {
    padding-left: 60px;
    padding-right: 60px;
  }
`;
