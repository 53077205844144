export default {
  // Media queries
  s: '@media (max-width: 768px)',
  m: '@media (min-width: 769px) and (max-width: 1024px)',
  l: '@media (min-width: 1025px)',
  sm: '@media (max-width: 1024px)',
  ml: '@media (min-width: 769px)',

  // Colors
  color: {
    brand: 'rgba(0, 43, 92, 1)',
    text: 'rgba(0, 43, 92, 1)',
    accent: 'rgba(234, 175, 15, 1)',
  },
};
