import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import FooterTagline from './FooterTagline';
import ThemeProvider from './ThemeProvider';
import Wrap from '../Wrap';

const AccelerateMenu = ({ data }) => (
  <Nav>
    {data.menu.showChallenges && data.challenges.totalCount > 0 && (
      <NavItem to="/challenges/" activeClassName="active">
        Challenges
      </NavItem>
    )}
    {data.menu.showUpdates && data.updates.totalCount > 0 && (
      <NavItem to="/updates/" activeClassName="active">
        Updates
      </NavItem>
    )}
    <NavItem to="/about/" activeClassName="active">
      About
    </NavItem>
    <NavItem to="/pages/faq">FAQ</NavItem>
    <NavItem to="/accelerate#contact">Contact</NavItem>
    <NavItem to="/">FMOx</NavItem>
  </Nav>
);

const XMenu = () => (
  <Nav>
    <NavItem to="/accelerate">xCELERATE</NavItem>
    <NavItem to="/pages/change">xCHANGE</NavItem>
    <NavItem to="/#contact">Contact</NavItem>
  </Nav>
);

const Layout = props => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "fmo-xcelerate.svg" }) {
        publicURL
      }
      logoWhite: file(relativePath: { eq: "fmo-white.svg" }) {
        publicURL
      }
      logoBlue: file(relativePath: { eq: "fmo-blue.svg" }) {
        publicURL
      }
      logoX: file(relativePath: { eq: "fmoxlogo.svg" }) {
        publicURL
      }
      content: contentfulFooter {
        contact1 {
          childMarkdownRemark {
            html
          }
        }
        contact2 {
          childMarkdownRemark {
            html
          }
        }
        contact3 {
          childMarkdownRemark {
            html
          }
        }
      }
      challenges: allContentfulChallenge {
        totalCount
      }
      updates: allContentfulUpdate {
        totalCount
      }
      menu: contentfulMenu {
        showChallenges
        showUpdates
      }
    }
  `);

  return (
    <ThemeProvider>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <Header>
          <HeaderInner>
            {props.hasXMenu ? (
              <Link to="/">
                <Logo
                  src={data.logoX.publicURL}
                  alt="Logo of FMO, Entrepreneurial Development Bank"
                />
              </Link>
            ) : (
              <Link to="/accelerate">
                <Logo src={data.logo.publicURL} />
              </Link>
            )}
            {props.hasXMenu ? <XMenu /> : <AccelerateMenu data={data} />}
          </HeaderInner>
        </Header>
        <Center className={props.className}>{props.children}</Center>
        {!props.hideFooter && (
          <>
            <FooterTagline css={{ flex: 'none' }} />
            <Footer>
              <FooterWrapper>
                <FooterGroup>
                  <a href="https://www.fmo.nl">
                    <FooterLogo
                      src={data.logoWhite.publicURL}
                      alt="Logo of FMO, Entrepreneurial Development Bank"
                    />
                  </a>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.content.contact1.childMarkdownRemark.html,
                    }}
                  />
                </FooterGroup>
                <FooterGroup
                  dangerouslySetInnerHTML={{
                    __html: data.content.contact2.childMarkdownRemark.html,
                  }}
                />
                <FooterGroup
                  dangerouslySetInnerHTML={{
                    __html: data.content.contact3.childMarkdownRemark.html,
                  }}
                />
              </FooterWrapper>
            </Footer>
            <span id="contact" />
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Layout;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  flex: none;
  padding: 24px 20px;
  ${p => p.theme.ml} {
    height: 100px;
    padding: 0 60px;
  }
`;

const HeaderInner = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  ${p => p.theme.ml} {
    display: flex;
    align-items: center;
  }
`;

const Logo = styled.img`
  height: 28px;
  ${p => p.theme.l} {
    height: 40px;
  }
`;

const Nav = styled.div`
  display: flex;
  ${p => p.theme.s} {
    margin-top: 10px;
  }
  ${p => p.theme.ml} {
    margin-left: auto;
  }
`;

const NavItem = styled(Link)`
  font-size: 17px;
  color: inherit;
  text-decoration: none;
  :hover,
  :focus {
    text-decoration: underline;
  }
  :not(:first-child) {
    margin-left: 15px;
  }
  ${p => p.theme.ml} {
    font-size: 20px;
    :not(:first-child) {
      margin-left: 30px;
    }
  }
  &.active {
    font-weight: bold;
  }
`;

const Center = styled.div`
  flex: 1 0 auto;
  margin-bottom: 60px;
`;

const Footer = styled.footer`
  flex: none;
  padding: 30px 0 60px;
  background: ${p => p.theme.color.brand};
  color: white;
  font-size: 18px;
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const FooterWrapper = styled(Wrap)`
  ${p => p.theme.ml} {
    display: flex;
  }
`;

const FooterGroup = styled.div`
  ${p => p.theme.s} {
    & + & {
      margin-top: 30px;
    }
  }
  ${p => p.theme.ml} {
    flex: 1;
    & + & {
      margin-left: 30px;
    }
  }
  white-space: pre-line;
`;

const FooterLogo = styled.img`
  display: block;
  width: 100%;
  max-width: 80px;
  margin-bottom: 1em;
`;
