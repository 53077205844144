import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Hero = props => {
  const files = useStaticQuery(graphql`
    query {
      xBackground: file(relativePath: { eq: "x-background.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Container className={props.className}>
      <HeroInner>
        <HeroLeft background={files.xBackground.publicURL} />
        <HeroRight>
          <HeroImage>
            <Img fluid={props.image.fluid} />
          </HeroImage>
          <HeroTitle
            textLength={Math.max(
              props.title[0].length,
              Math.max(props.title[1].length)
            )}
          >
            <span className={props.titleIndent === 'first' ? 'indent' : ''}>
              {props.title[0]}
            </span>
            <br />
            <span className={props.titleIndent === 'second' ? 'indent' : ''}>
              {props.title[1]}
            </span>
          </HeroTitle>
        </HeroRight>
      </HeroInner>
    </Container>
  );
};

Hero.propTypes = {
  className: PT.string,
  title: PT.arrayOf([PT.string.isRequired]).isRequired,
  titleIndent: PT.oneOf(['first', 'second']).isRequired,
};

Hero.defaultProps = {
  titleIndent: 'first',
};

export default Hero;

const Container = styled.section`
  background: white
    linear-gradient(
      90deg,
      rgba(217, 235, 243, 1) 0%,
      rgba(217, 235, 243, 0.13) 50%
    );
  position: relative;
  ${p => p.theme.sm} {
    margin-bottom: 60px;
  }
  ${p => p.theme.l} {
    :after {
      position: absolute;
      content: '';
      bottom: 0;
      height: 60px;
      width: calc(100% - 120px);
      max-width: 900px;
      left: 50%;
      transform: translateX(-50%);
      background: white;
    }
  }
`;

const HeroInner = styled.div`
  position: relative;
  ${p => p.theme.l} {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const HeroLeft = styled.div`
  ${p => p.theme.sm} {
    display: none;
  }
  ${p => p.theme.l} {
    flex: 1 1 47%;
    overflow: hidden;
    position: relative;
    :before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('${p => p.background}');
      background-size: contain;
      background-position: top left;
      background-repeat: no-repeat;
    }
  }
`;

const HeroRight = styled.div`
  ${p => p.theme.l} {
    flex: 1 1 53%;
    overflow: hidden;
  }
`;

const HeroImage = styled.div`
  width: 100%;
`;

const HeroTitle = styled.h1`
  color: ${p => p.theme.color.text};
  line-height: 1;
  margin: 0 auto;
  z-index: 2;
  position: absolute;
  font-family: Futura;
  font-weight: bold;
  ${p => p.theme.sm} {
    left: 45%;
    top: 38%;
    transform: translate(-50%, -50%);
  }
  ${p => p.theme.l} {
    top: 38%;
    left: 50%;
    transform: translate(-62%, -50%);
  }
  font-size: ${p => 6.7 - Math.max(0, p.textLength - 21) * 0.22}vw;
  @media (min-width: 1200px) {
    font-size: ${p => 80.4 - Math.max(0, p.textLength - 21) * 2.64}px;
  }
  span {
    display: inline-block;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    padding: 0.2em 0.5em;
    ${p => p.theme.l} {
      background: transparent
        linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.7) 50%
        );
      padding: 0.1em 0.2em;
    }
    &.indent {
      margin-left: 1em;
      ${p => p.theme.l} {
        margin-left: 1.5em;
      }
    }
  }
  span:nth-of-type(2) {
    font-family: BlackerText;
    font-size: 0.9em;
    font-style: italic;
  }
`;
