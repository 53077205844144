import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const CTAButton = ({
  className,
  onClick,
  children,
  href = '',
  isSubmit = false,
}) => {
  const arrow = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "arrow-right.svg" }) {
        publicURL
      }
    }
  `);

  let ButtonComponent = ButtonSubmit;
  if (!isSubmit) {
    ButtonComponent =
      href.startsWith('http://') ||
      href.startsWith('https://') ||
      href.startsWith('#')
        ? ButtonA
        : ButtonLink;
  }

  return (
    <ButtonComponent
      className={className}
      to={href}
      href={href}
      onClick={onClick}
      type={isSubmit ? 'submit' : ''}
    >
      <Arrow url={arrow.file.publicURL} />
      {children}
    </ButtonComponent>
  );
};

export default CTAButton;

const ButtonA = styled.a`
  position: relative;
  display: inline-block;
  height: 53px;
  line-height: 55px;
  background: #eaaf0f;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.16);
  border-radius: 27px;
  font-family: Futura;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  padding: 0 24px 0 53px;
  font-size: 17px;
  ${p => p.theme.ml} {
    padding: 0 32px 0 53px;
    font-size: 20px;
  }
  :hover,
  :focus {
    opacity: 0.9;
  }
  :active {
    opacity: 0.85;
  }
`;

const ButtonLink = ButtonA.withComponent(Link);
const ButtonSubmit = ButtonA.withComponent('button');

const Arrow = styled.div`
  height: 16px;
  width: 16px;
  left: 20px;
  top: 18px;
  position: absolute;
  background: url('${p => p.url}');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  ${p => p.theme.ml} {
    height: 18px;
    width: 18px;
    left: 19px;
    top: 17px;
  }
`;
