import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import theme from '../../theme';

const CustomThemeProvider = props => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {props.children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
  }

  body {
    font-size: 16px;
    line-height: 1.66;
    font-family: Futura;
    color: ${p => p.theme.color.text};
    height: 100%;
  }

  #___gatsby, #___gatsby > #gatsby-focus-wrapper {
    height: 100%;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
  }

  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    padding: 0;
    margin: 0;
    border: 0;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
`;
