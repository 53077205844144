import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const FooterTagline = props => {
  const data = useStaticQuery(graphql`
    query {
      content: contentfulFooter {
        title1
        title2
        backgroundImage {
          fluid(
            maxWidth: 1440
            maxHeight: 432
            cropFocus: CENTER
            quality: 90
          ) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `);

  return (
    <Container className={props.className}>
      <Img fluid={data.content.backgroundImage.fluid} />
      <Title>
        <span>{data.content.title1}</span>
        <br />
        <span>{data.content.title2}</span>
      </Title>
    </Container>
  );
};

export default FooterTagline;

const Container = styled.section`
  position: relative;
`;

const Title = styled.h1`
  color: ${p => p.theme.color.text};
  line-height: 1;
  margin: 0 auto;
  z-index: 2;
  position: absolute;
  font-family: Futura;
  font-weight: bold;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -62%);
  font-size: 6.1vw;
  @media (min-width: 1200px) {
    font-size: 74px;
  }
  span {
    display: inline-block;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    padding: 0.2em 0.5em;
    ${p => p.theme.l} {
      padding: 0.1em 0.2em;
    }
  }
  span:nth-of-type(1) {
    margin-left: 1em;
    ${p => p.theme.l} {
      margin-left: 1.5em;
    }
  }
  span:nth-of-type(2) {
    font-family: BlackerText;
    font-size: 0.9em;
    font-style: italic;
  }
`;
